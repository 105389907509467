import { isISO31661Alpha2, isUUID, maxLength } from 'class-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  Maybe,
  Result,
  emptyOrWhiteSpaceStringAsNone,
} from 'typescript-functional-extensions';

import { StringLengthsEnum } from './../enum/string-lengths.enum';
import { countryCodes } from './country-codes';

export function replaceAll(value: string, search: string, replacement: string) {
  return value.split(search).join(replacement);
}

export function isEmptyOrWhitespace(
  value: string | undefined | null,
): value is undefined | null | '' {
  if (!value) {
    return true;
  }

  return value.trim() === '';
}

export function isNotEmptyOrWhitespace(value: string | undefined | null) {
  return !isEmptyOrWhitespace(value);
}

export function nullIfEmpty(value: string | undefined | null) {
  return isEmptyOrWhitespace(value) ? null : value;
}

export function uuidOrNull(value: string | undefined | null) {
  return isUUID(value) ? (value as string) : null;
}

export function noneOrValid<T>(validator: (value: T) => boolean) {
  return (value: Maybe<T>) =>
    value.hasNoValue || value.map(validator).getValueOrDefault(false);
}

const curriedMaxLength = (max: number) => (value: string) =>
  maxLength(value, max);

export function noneOrValidLength(
  stringValue: Maybe<string>,
  maximumLength: number,
  tooLongErrorMessage: string,
): Result<Maybe<string>, string> {
  return Result.success(stringValue.bind(emptyOrWhiteSpaceStringAsNone)).ensure(
    noneOrValid(curriedMaxLength(maximumLength)),
    tooLongErrorMessage,
  );
}

export function stringWithValidLength(
  stringValue: Maybe<string>,
  maximumLength: number,
  emptyErrorMessage: string,
  tooLongErrorMessage: string,
): Result<string, string> {
  return stringValue
    .bind(emptyOrWhiteSpaceStringAsNone)
    .toResult(emptyErrorMessage)
    .ensure(curriedMaxLength(maximumLength), tooLongErrorMessage);
}

export function optionalStringWithValidLength(
  stringValue: Maybe<string>,
  maximumLength: number,
  tooLongErrorMessage: string,
) {
  return Result.success(stringValue.bind(emptyOrWhiteSpaceStringAsNone)).ensure(
    noneOrValid(curriedMaxLength(maximumLength)),
    tooLongErrorMessage,
  );
}

export function validateOptionalDescriptionLength(
  description: string | null,
): boolean {
  if (description && description.length > StringLengthsEnum.CharsLength1000) {
    return false;
  }

  return true;
}

export function parseIfNotEmpty<T>(
  value: string | undefined | null,
  parser: (value: string) => T,
) {
  return isEmptyOrWhitespace(value) ? null : parser(value);
}

const SLUG_REGEXP = /^[a-z0-9](-?[a-z0-9])*$/;

export function isSlug(value: string) {
  if (isEmptyOrWhitespace(value)) {
    return false;
  }

  return matchExact(SLUG_REGEXP, value) ?? false;
}

const ALPHANUMERIC_REGEXP = /^[a-zA-Z0-9]*$/;

export function isAlphanumeric(value: string) {
  if (isEmptyOrWhitespace(value)) {
    return false;
  }

  return matchExact(ALPHANUMERIC_REGEXP, value);
}

/** Country code in ISO 3166-1 alfa-2  */
export type CountryCode = (typeof countryCodes)[number];

export const isCountryCode = isISO31661Alpha2 as (
  value: unknown,
) => value is CountryCode;

export function cleanupPhoneNumber(value: string) {
  if (!value) {
    return '';
  }
  const result = parsePhoneNumberFromString(value);

  return result?.number.toString() || '';
}

function matchExact(r: RegExp, value: string) {
  const match = value.match(r);

  return match && value === match[0];
}

export function getSubstringOrString(
  value: string,
  max: number,
  decorator = '...',
) {
  if (!value) {
    return value;
  }

  return value.length <= max ? value : `${value.substring(0, max)}${decorator}`;
}

export const serializeError = (value: unknown) =>
  JSON.stringify(value, Object.getOwnPropertyNames(value));

export const invalidSenderId = () => {
  return 'invalid!@#$';
};
