/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import { Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { ns } from '@proptly/locale';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { logout } from '@proptly/ui-core/lib/supertokens';

import { AppError } from '../../../errors/app-errors';
import { useOptionalContext } from '../../../hooks/use-optional-context';

export const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
  const session = useOptionalContext(useSessionContext);
  const isLoggedIn = !!session && !session.loading && session.doesSessionExist;
  const [errt] = useTranslation(ns.Errors);
  const [t] = useTranslation();

  if (
    error.name === 'ChunkLoadError' ||
    error.message?.includes('Loading CSS chunk') ||
    error.message?.includes('Failed to fetch dynamically imported module') ||
    error.message?.includes('Importing a module script failed') ||
    error.message?.includes("'text/html' is not a valid JavaScript MIME type.")
  ) {
    window.location.reload();

    return null;
  }

  let errorName = error.name;
  let errorMessage = error.message;
  let errorHint: ReactNode | string | undefined = (
    <Trans t={errt} i18nKey="otherError" />
  );

  if (error instanceof AxiosError) {
    const response = error.response;
    if (response?.status) {
      errorName = response.status + '';
    }
    if (response?.statusText) {
      errorMessage = response.statusText;
    }
    errorHint = (
      <Trans
        t={errt}
        i18nKey={errorName as any}
        defaults="$t(returnToHomepage)"
      />
    );
  }

  if (error instanceof AppError) {
    errorName = error.status;
    errorMessage = error.statusText;
    errorHint = (
      <Trans
        t={errt}
        i18nKey={errorName as any}
        defaults="$t(returnToHomepage)"
      />
    );
  }

  if (errorName === 'AxiosError') {
    errorName = errt('axiosError');
  }

  return (
    <div>
      <Heading color="primary.default" as="h1" mb="2">
        {errorName}
      </Heading>
      <Text textStyle="bodyLarge" color="primary.default" mb="2">
        {errorMessage}
      </Text>
      <Text maxW="440px" mb="4" whiteSpace="pre-wrap">
        {errorHint}
      </Text>
      <Flex gap="4">
        <Button onClick={() => resetErrorBoundary()}>{t('tryAgain')}</Button>
        {isLoggedIn && <Button onClick={logout}>{t('signOut')}</Button>}
      </Flex>
    </div>
  );
};

export const FullPageErrorFallback: typeof ErrorFallback = (props) => (
  <Container py="10">
    <ErrorFallback {...props} />
  </Container>
);
