export type Environment = 'development' | 'staging' | 'production' | 'ci';

export function getEnv(): Environment {
  if (typeof process !== 'undefined' && process?.env?.NODE_ENV === 'test') {
    return 'ci';
  }

  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return 'development';
  }

  if (hostname.includes('staging.proptly.no')) {
    return 'staging';
  }

  return 'production';
}
