/* eslint-disable no-console */
import { AxiosInstance } from 'axios';

import { IS_IN_WEB_VIEW } from './is-in-web-view';
import { registerDeviceForPushNotifications } from './register-for-push-notifications';
import { unregisterDeviceFromPushNotifications } from './unregister-from-push-notifications';
import { WebViewPlatform, getDevicePushToken } from './web-view-action';

export const platformToDeviceTypeMap = new Map([
  ['ios', 'APPLE' as const],
  ['android', 'ANDROID' as const],
]);

export class MobilePushNotifications {
  private static instance?: MobilePushNotifications;

  static isSupported = IS_IN_WEB_VIEW;
  static get isInitialized() {
    return !!this.instance;
  }
  static get isRegistered() {
    return !!this.instance?.registration;
  }

  static init(apiClient: AxiosInstance) {
    if (!this.isSupported) {
      console.warn(
        '[MobilePushNotifications] is not supported skipping initialization',
      );

      return;
    }

    if (this.instance) {
      console.warn(
        '[MobilePushNotifications] is already initialized skipping initialization',
      );

      return;
    }

    this.instance = new MobilePushNotifications(apiClient);
  }

  static async unregister() {
    if (!this.instance) {
      console.warn(
        '[MobilePushNotifications] not initialized skipping unregistration',
      );

      return;
    }

    try {
      return await this.instance.unregister();
    } catch (error) {
      console.warn(
        `[MobilePushNotifications] encountered an error during unregistration ${error}`,
      );

      return;
    }
  }

  static async register() {
    if (!this.instance) {
      console.warn(
        '[MobilePushNotifications] not initialized skipping registration',
      );

      return;
    }

    try {
      return await this.instance.register();
    } catch (error) {
      console.warn(
        `[MobilePushNotifications] encountered an error during registration ${error}`,
      );

      return;
    }
  }

  private registration?: {
    token: string;
    platform: WebViewPlatform;
  };

  private constructor(private readonly apiClient: AxiosInstance) {}

  private async getRegistration() {
    if (this.registration) {
      return this.registration;
    }

    const registration = await getDevicePushToken();
    this.registration = registration;

    return registration;
  }

  private async unregister() {
    if (!this.registration) {
      return;
    }

    await unregisterDeviceFromPushNotifications(this.apiClient, {
      deviceId: this.registration.token,
    });
  }

  private async register() {
    const registration = await this.getRegistration();
    const deviceType = platformToDeviceTypeMap.get(registration.platform);

    if (!deviceType) {
      return;
    }

    await registerDeviceForPushNotifications(this.apiClient, {
      deviceId: registration.token,
      deviceType,
    });
  }
}
