export default {
  ENVIRONMENT: 'test',
  ADMIN_APP_URL: 'http://localhost:4500',
  AUTH_API_URL: 'http://localhost:5200',
  AUTH_APP_URL: 'http://localhost:4400',
  CHAT_API_URL: 'http://localhost:5400',
  CONTRACTOR_API_URL: 'http://localhost:5001',
  CONTRACTOR_CLIENT_APP_URL: 'http://localhost:4201',
  CONTRACTOR_EMPLOYEE_APP_URL: 'http://localhost:4200',
  CONTRACTOR_GTM_ID: 'GTM-NN6V6JK',
  CONTRACTOR_SDK_URL: 'http://localhost:4202',
  APP_DOMAIN: 'localhost',
  FILE_API_URL: 'http://localhost:5500',
  INTERCOM_API_BASE: 'https://api-iam.intercom.io',
  INTERCOM_APP_ID: '',
  RECAPTCHA_SITE_KEY: '6Lc-jG0hAAAAAJHVbBsp1c9wcln6oyJ0pHHTzLSh',
  ROUTING_API_URL: 'http://localhost:5100',
  ROUTING_APP_URL: 'http://localhost:4300',
  ROUTING_GTM_ID: '',
  TIMELINE_API_URL: 'http://localhost:5300',
  WEBFORM_APP_URL: 'http://localhost:4203',
  MAPBOX_API_KEY:
    'pk.eyJ1Ijoia2dqZXJkZSIsImEiOiJjbHF4a3k5ZDUwZXZnMmpsMm5tZ2YyYWV4In0.AKumnu6yCEyxnswgiEYC-w',
  I18N_DEBUGGING: false,
  MOCK_SERVICE_WORKER_ENABLE: false,
  API_URL: 'http://localhost:8000',
  METABASE_INSTANCE_URL: 'https://metabase.proptly.tech',
  METABASE_AUTH_PROVIDER_URL: 'http://localhost:8000/api/analytics/auth',
};
